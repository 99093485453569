import React from 'react';
import { graphql } from 'gatsby';
import { StructuredTextDocument } from 'react-datocms';
import { render } from 'datocms-structured-text-to-plain-text';
import Container from '../../../primitives/grid/container';
import Typography from '../../../primitives/typography';
import Background from '../../../primitives/backgrounds';
import { breakpoints } from '../../../primitives/tokens';
import ShadowLogo from '../solutions-carousel/shadow-logo.svg';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import MediaItem from '../../../primitives/media-item';
import TabletHorizontal from '../../../primitives/devices/tablet-horizontal';
import AspectRatio from '../../../primitives/aspect-ratio';

export type BelowTheFoldIntroductionProps = Queries.BelowTheFoldIntroductionPropsFragment;

const BelowTheFoldIntroduction = ({ extraLargeTitle, richTitle, richText, mediaItem }: BelowTheFoldIntroductionProps): JSX.Element => (
    <div css={{
        position: 'relative',
    }}
    >
        <Background
            breakpoints={{
                dt: {
                    gradient: 'orange',
                    clip: mediaItem && mediaItem.length > 0 ? 'bottom' : 'none',
                },
            }}
            css={{
                position: 'absolute',
                overflow: 'hidden',
                height: mediaItem && mediaItem.length > 0 ? 'calc(100% - 154px)' : '100%',
                width: '100%',
            }}
        >
            <ShadowLogo css={{
                position: 'absolute',
                right: '0',
                top: '0',
                transform: 'translateY(-37%)',
                pointerEvents: 'none',
                [breakpoints.mb]: {
                    transform: 'translateX(30%) translateY(-40%) scale(0.4)',
                },
            }}
            />
        </Background>
        <Container css={{
            position: 'relative',
            textAlign: 'center',
            paddingTop: '114px',
            paddingBottom: mediaItem && mediaItem.length > 0 ? '0px' : '94px',
            [breakpoints.mb]: {
                paddingTop: '80px',
                paddingBottom: mediaItem && mediaItem.length > 0 ? '0px' : '50px',
            },
        }}
        >
            <Typography
                fontWeight="light"
                color="shadesWhite"
                css={{
                    maxWidth: '680px',
                    margin: 'auto',
                }}
                fontSize={extraLargeTitle ? {
                    dt: 5660,
                    tb: 4053,
                    mb: 2429,
                } : {
                    dt: 4053,
                    mb: 2429,
                }}
                override
            >
                <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
            </Typography>
            {richText && render(richText) && (
                <Typography
                    css={{
                        opacity: 0.8,
                        paddingTop: '16px',
                        maxWidth: '460px',
                        margin: 'auto',
                        [breakpoints.mb]: {
                            paddingTop: '10px',
                        },
                    }}
                    color="shadesWhite"
                    fontSize={{
                        dt: 1822,
                        mb: 1420,
                    }}
                    override
                >
                    <CustomStructuredTextRenderer data={richText as unknown as StructuredTextDocument} />
                </Typography>
            )}
            {mediaItem && mediaItem.length > 0 && (
                <AspectRatio
                    breakpoints={{
                        dt: { width: 16, height: 9 },
                    }}
                    css={{
                        marginTop: '60px',
                        maxWidth: '840px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        zIndex: 1,
                        boxShadow: '0 24px 54px #0004',
                    }}
                >
                    <MediaItem item={mediaItem} css={{ width: '100%', height: '100%' }} />
                </AspectRatio>
            )}
        </Container>
    </div>
);

export default BelowTheFoldIntroduction;

export const query = graphql`
    fragment BelowTheFoldIntroductionProps on DatoCmsBelowTheFoldIntroduction {
        extraLargeTitle
        richTitle {
            blocks
            value
            links
        }
        richText {
            blocks
            value
            links
        }
        mediaItem {
            ...MediaItemProps
        }
    }
`;
